export default {
  categories: {
    many: () => "categories",
    one: (id: string) => `categories/${id}`
  },
  articles: {
    many: () => "articles",
    one: (id: string) => `articles/${id}`
  },
  integrations: {
    azure: {
      sync: () => "integrations/azure/sync"
    }
  }
}
