import { del } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface DeleteWellnessCalendarParams {
  id: number
}

export default asyncThunkFactory<number, DeleteWellnessCalendarParams>(
  NAMESPACE,
  "deleteWellnessCalendar",
  async (params) => {
    const path = ["uploads", "wellness-calendars", params.id].join("/")
    const response = await del<number>(path)
    return response.data
  }
)
