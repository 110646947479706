import { createSlice } from "@reduxjs/toolkit"
import { moment } from "common/libs"
import {
  defaultListedApiResource,
  handleApiResourceBaseFulfilled,
  handleApiResourceBasePending,
  handleApiResourceBaseRejected,
  handleListedApiResourceFulfilled,
  handleListedApiResourceRejected,
  ListedApiResource
} from "../helpers"
import createWellnessCalendarAction from "./actions/create-wellness-calendar.action"
import deleteWellnessCalendarAction from "./actions/delete-wellness-calendar.action"
import fetchWellnessCalendarsAction, {
  WellnessCalendarDto
} from "./actions/fetch-wellness-calendars.action"
import { NAMESPACE } from "./constants"

interface UploadsState {
  wellnessCalendars: ListedApiResource<WellnessCalendarDto>
  lastWellnessCalendarsUpdateTime: string
}

const initialState: UploadsState = {
  wellnessCalendars: defaultListedApiResource(),
  lastWellnessCalendarsUpdateTime: moment().format()
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchWellnessCalendarsAction.pending, (state) =>
        handleApiResourceBasePending(state.wellnessCalendars)
      )
      .addCase(fetchWellnessCalendarsAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(
          state.wellnessCalendars,
          action.payload.data
        )
      )
      .addCase(fetchWellnessCalendarsAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.wellnessCalendars, action.error)
      )
      .addCase(createWellnessCalendarAction.pending, (state) =>
        handleApiResourceBasePending(state.wellnessCalendars)
      )
      .addCase(createWellnessCalendarAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.wellnessCalendars)
        state.lastWellnessCalendarsUpdateTime = moment().format()
      })
      .addCase(createWellnessCalendarAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(state.wellnessCalendars, action.error)
      )
      .addCase(deleteWellnessCalendarAction.pending, (state) => {
        handleApiResourceBasePending(state.wellnessCalendars)
      })
      .addCase(deleteWellnessCalendarAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.wellnessCalendars)
        state.lastWellnessCalendarsUpdateTime = moment().format()
      })
      .addCase(deleteWellnessCalendarAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(state.wellnessCalendars, action.error)
      )
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default { reducer: slice.reducer }
