import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, post, put } from "common/api/api"
import endpoints from "common/api/endpoints"
import { OrderingParams, PaginationParams } from "common/api/types"
import { ArticleDto } from "common/dtos/article.dto"
import { SerializedError } from "common/types"
import { serializeApiError } from "common/utils/errors.utils"
import { notifyError } from "common/utils/toasts.utils"
import { handleUnauthorizedApiResponseError } from "../auth/utils"
import { CreateArticleDto } from "./types/create-article.dto"
import { EditArticleDto } from "./types/edit-article.dto"
import { FetchArticlesDto } from "./types/fetch-articles.dto"

interface FetchArticlesArgs {
  params?: PaginationParams &
    OrderingParams & {
      departmentId?: string
      searchPhrase?: string
    }
}

export const fetchArticles = createAsyncThunk<
  FetchArticlesDto,
  FetchArticlesArgs,
  { rejectValue: SerializedError }
>("articles/fetchArticles", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await get<FetchArticlesDto>(endpoints.articles.many(), {
      params
    })
    return response.data
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface FetchArticleArgs {
  articleId: string
}

export const fetchArticle = createAsyncThunk<
  ArticleDto,
  FetchArticleArgs,
  { rejectValue: SerializedError }
>("articles/fetchArticle", async ({ articleId }, { rejectWithValue }) => {
  try {
    const response = await get<ArticleDto>(endpoints.articles.one(articleId))
    return response.data
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface CreateArticleArgs {
  dto: CreateArticleDto
}

export const createArticle = createAsyncThunk<
  void,
  CreateArticleArgs,
  { rejectValue: SerializedError }
>("articles/createArticle", async ({ dto }, { rejectWithValue }) => {
  try {
    await post(endpoints.articles.many(), {
      data: dto,
      headers: {
        "content-type": "multipart/form-data"
      }
    })
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface EditArticleArgs {
  articleId: string
  dto: EditArticleDto
}

export const editArticle = createAsyncThunk<
  void,
  EditArticleArgs,
  { rejectValue: SerializedError }
>("articles/editArticle", async ({ articleId, dto }, { rejectWithValue }) => {
  try {
    await put(endpoints.articles.one(articleId), {
      data: dto,
      headers: {
        "content-type": "multipart/form-data"
      }
    })
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface DeleteArticleArgs {
  articleId: string
}

export const deleteArticle = createAsyncThunk<
  void,
  DeleteArticleArgs,
  { rejectValue: SerializedError }
>("articles/deleteArticle", async ({ articleId }, { rejectWithValue }) => {
  try {
    await del(endpoints.articles.one(articleId))
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    notifyError("Couldn't dlete article!", serializedError)
    return rejectWithValue(serializedError)
  }
})
