import React from "react"
import paths from "common/config/paths"
import { Route } from "common/types/Route"

export default [
  {
    path: paths.articles.create,
    isPrivate: true,
    component: React.lazy(() => import("./views/CreateArticleFormView"))
  },
  {
    path: paths.articles.edit,
    isPrivate: true,
    component: React.lazy(() => import("./views/EditArticleFormView"))
  },
  {
    path: paths.articles.root,
    isPrivate: true,
    component: React.lazy(() => import("./views/ArticleListView"))
  }
] as Route[]
