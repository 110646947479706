import { createSlice } from "@reduxjs/toolkit"
import * as auth from "common/auth"
import { login as loginThunk, logout as logoutThunk } from "./thunks"

export const SLICE_NAME = "commonAuth"

export interface AuthState {
  isLoading: boolean
}

const initialState: AuthState = {
  isLoading: false
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loginThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        loginThunk.fulfilled,
        (state, { payload: { accessToken, expiresAt } }) => {
          auth.login(accessToken, expiresAt)
          state.isLoading = false
        }
      )
      .addCase(loginThunk.rejected, (state) => {
        auth.logout()
        state.isLoading = false
      })
      .addCase(logoutThunk, () => {
        auth.logout()
      })
})

export default { reducer: slice.reducer, actions: slice.actions }
