import { configureStore, combineReducers } from "@reduxjs/toolkit"

import articlesSlice from "./articles/slice"
import authSlice from "./auth/slice"
import categoriesSlice from "./categories/slice"
import integrationsSlice from "./integrations/slice"
import schedulesSlice from "./schedules/slice"
import usersSlice from "./users/slice"
import uploadsSlice from "./uploads/slice"
import dashboardSlice from "./dashboard/slice"

const reducer = combineReducers({
  articles: articlesSlice.reducer,
  auth: authSlice.reducer,
  categories: categoriesSlice.reducer,
  integrations: integrationsSlice.reducer,
  schedules: schedulesSlice.reducer,
  users: usersSlice.reducer,
  uploads: uploadsSlice.reducer,
  dashboard: dashboardSlice.reducer
})

export const store = configureStore({
  reducer
})

export type RootState = ReturnType<typeof reducer>

export default store
