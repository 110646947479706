import { Module } from "common/types/Module"

import { default as categoriesModule } from "./categories"
import { default as loginModule } from "./login"
import { default as articlesModule } from "./articles"
import { default as dashboardModule } from "./dashboard"
import { default as schedulesModule } from "./schedules"
import { default as wellnessCalendarsModule } from "./wellness-calendars"

export const modules = [
  loginModule,
  articlesModule,
  categoriesModule,
  dashboardModule,
  schedulesModule,
  wellnessCalendarsModule
] as Module[]
