import React from "react"
import { Route } from "common/types/Route"
import paths from "common/config/paths"

export default [
  {
    path: paths.wellnessCalendars.root,
    isPrivate: true,
    component: React.lazy(() => import("./views/WellnessCalendarListView"))
  },
  {
    path: paths.wellnessCalendars.create,
    isPrivate: true,
    component: React.lazy(
      () => import("./views/CreateWellnessCalendarFormView")
    )
  }
] as Route[]
