export default {
  root: "/articles",
  login: "/login",
  dashboard: {
    root: "/dashboard"
  },
  articles: {
    root: "/articles",
    create: "/articles/create",
    edit: "/articles/edit/:articleId"
  },
  categories: {
    root: "/categories",
    create: "/categories/create",
    edit: "/categories/edit/:categoryId"
  },
  schedules: {
    root: "/schedules"
  },
  wellnessCalendars: {
    root: "/wellness-calendars",
    create: "/wellness-calendars/create"
  }
}
