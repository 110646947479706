import { createAsyncThunk } from "@reduxjs/toolkit"
import { post } from "common/api/api"
import endpoints from "common/api/endpoints"

export const runAzureSync = createAsyncThunk(
  "integrations/runAzureSync",
  async (_, { rejectWithValue }) => {
    try {
      await post(endpoints.integrations.azure.sync())
    } catch (err) {
      rejectWithValue(err)
    }
  }
)
