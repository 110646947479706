import { toast } from "react-toastify"

export const notifyError = (message: string, error?: any) => {
  if (error) {
    toast(`${message} ${error.message}. [${error.status}]`, { type: "error" })
  } else {
    toast(message, { type: "error" })
  }
}

export const notifySuccess = (message: string) => {
  toast(message, { type: "success" })
}
