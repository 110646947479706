import { createSlice } from "@reduxjs/toolkit"
import { notifyError, notifySuccess } from "common/utils/toasts.utils"
import { handleUnauthorizedApiResponseError } from "../auth/utils"
import * as thunks from "./thunks"
import { FetchArticleDto } from "./types/fetch-article.dto"
import { FetchArticlesDto } from "./types/fetch-articles.dto"

export const SLICE_NAME = "articles"

export interface ArticlesState {
  isFetchLoading: boolean
  isPersistingLoading: boolean
  articles: FetchArticlesDto | null
  article?: FetchArticleDto
}

const initialState: ArticlesState = {
  isFetchLoading: false,
  isPersistingLoading: false,
  articles: null,
  article: undefined
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(thunks.fetchArticles.pending, (state) => {
        state.isFetchLoading = true
      })
      .addCase(thunks.fetchArticles.rejected, (state, action) => {
        state.isFetchLoading = false
        notifyError("Couldn't get articles!", action.payload)
        handleUnauthorizedApiResponseError(action.payload)
      })
      .addCase(thunks.fetchArticles.fulfilled, (state, { payload }) => {
        if (payload) state.articles = payload
        state.isFetchLoading = false
      })
      .addCase(thunks.createArticle.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.createArticle.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't create article!", payload)
        handleUnauthorizedApiResponseError(payload)
      })
      .addCase(thunks.createArticle.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Article created!")
      })
      .addCase(thunks.fetchArticle.pending, (state) => {
        state.article = undefined
        state.isFetchLoading = true
      })
      .addCase(thunks.fetchArticle.rejected, (state, { payload }) => {
        state.isFetchLoading = false
        notifyError("Couldn't getch article!", payload)
        handleUnauthorizedApiResponseError(payload)
      })
      .addCase(thunks.fetchArticle.fulfilled, (state, { payload }) => {
        if (payload) state.article = payload
        state.isFetchLoading = false
      })
      .addCase(thunks.editArticle.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.editArticle.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't update article!", payload)
        handleUnauthorizedApiResponseError(payload)
      })
      .addCase(thunks.editArticle.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Article updated!")
      })
      .addCase(thunks.deleteArticle.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.deleteArticle.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't remove article!", payload)
        handleUnauthorizedApiResponseError(payload)
      })
      .addCase(thunks.deleteArticle.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Article deleted!")
      })
})

export default { reducer: slice.reducer }
