import { createSlice } from "@reduxjs/toolkit"
import * as thunks from "./thunks"

export const SLICE_NAME = "integrations"

export interface IntegrationsState {
  isAzureSyncLoading: boolean
}

const initialState: IntegrationsState = {
  isAzureSyncLoading: false
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(thunks.runAzureSync.pending, (state) => {
        state.isAzureSyncLoading = true
      })
      .addCase(thunks.runAzureSync.rejected, (state) => {
        state.isAzureSyncLoading = false
      })
      .addCase(thunks.runAzureSync.fulfilled, (state) => {
        state.isAzureSyncLoading = false
      })
})

export default { reducer: slice.reducer }
