import React from "react"
import ReactDOM from "react-dom"
import "./common/fonts"
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css" // WYSIWYG Editory Styling
import "react-toastify/dist/ReactToastify.css"
import "./index.css"
import "./common/i18n"
import App from "common/components/App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(<App />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
