import React, { FunctionComponent } from "react"
import loaderImg from "common/resources/icons/loader.svg"
import { numericBoolean } from "common/utils/number.utils"
import { Wrapper, Loader } from "./Spinner.style"

export type LoaderSize = "small" | "medium" | "big"

interface SpinnerProps {
  size?: LoaderSize
  white?: boolean
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  size = "small",
  white = false,
  ...props
}) => (
  <Wrapper white={numericBoolean(white)} {...props}>
    <Loader src={loaderImg} size={size} />
  </Wrapper>
)

export default Spinner
