import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import * as msal from "@azure/msal-browser"
import * as authConfig from "common/config/auth-config"

const msalObject = new msal.PublicClientApplication(authConfig.msalConfig)

interface LoginPayload {
  accessToken: string
  expiresAt: number
}

export const login = createAsyncThunk<LoginPayload>(
  "auth/login",
  async (_, { rejectWithValue }) => {
    try {
      const { accessToken, expiresOn } = await msalObject.loginPopup(
        authConfig.loginRequest
      )
      return { accessToken, expiresAt: Number(expiresOn) }
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const logout = createAction("logout")
