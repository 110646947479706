import { ToastContainer } from "react-toastify"
import styled from "styled-components"

export const StyledToastContainer = styled(ToastContainer).attrs({
  progressClassName: "progress"
})`
  .toast-error {
    color: #002a5f;
    background-color: #ffe3e0;
  }

  .toast-success {
    color: #002a5f;
    background-color: #e4ffe0;
  }

  .progress {
    background: #002a5f;
  }
`
