import { createMuiTheme } from "@material-ui/core"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    desktop_xs: true
    desktop_sm: true
    desktop_md: true
    desktop_lg: true
    desktop_xl: true
  }
}

export const palette = {
  background: {
    paper: "#f6f6f6"
  },
  info: {
    light: "#d3d4d5",
    main: "#bababa",
    dark: "#4e585d",
    100: "#E4E2E2"
  },
  primary: {
    main: "#002a5f",
    light: "#eaeff5",
    dark: "#007bb6"
  },
  secondary: {
    main: "#fff",
    light: "#007bb6",
    dark: "#fff"
  },
  text: {
    primary: "#002B5F",
    secondary: "#0c1f28",
    hint: "#EEEEEE"
  },
  success: {
    main: "#19AD79"
  },
  error: {
    main: "#EA5C52"
  }
}

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1720,
  desktop_xs: 1024,
  desktop_sm: 1280,
  desktop_md: 1440,
  desktop_lg: 1600,
  desktop_xl: 1920
}

const breakpoints = createBreakpoints({
  values: BREAKPOINTS
})

const theme = createMuiTheme({ palette })

export default createMuiTheme({
  palette,
  breakpoints: {
    ...theme.breakpoints,
    ...breakpoints
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: 20,
        height: 46,
        textTransform: "capitalize",
        borderRadius: 3,
        padding: "6px 24px",
        fontSize: 18,
        lineHeight: "19px",
        letterSpacing: 0.25
      },
      text: {
        color: "#414042",
        padding: "6px 16px",
        textTransform: "none"
      },
      contained: {
        boxShadow: "0 2px 4px 2px rgba(0,42,95,0.02)"
      },
      containedSecondary: {
        color: theme.palette.common.white
      }
    }
  }
})
