import { styled, WithTheme } from "@material-ui/core"
import styledComponent, { keyframes } from "styled-components"

import { LoaderSize } from "./Spinner"

interface WrapperProps extends WithTheme {
  white?: number
}

const spin = keyframes`
  0% {
    animation-timing-function: ease-in;
    transform: rotate( 0deg ) scale( 1 );
  }
  25% {
    animation-timing-function: ease-out;
    transform: rotate( -180deg ) scale( 1.25 );
  }
  75%, 100% {
    transform: rotate( -360deg ) scale( 1 );
  }
`

const getLoaderSize = (size: LoaderSize) => {
  if (size === "small") return 30
  if (size === "medium") return 48
  return 64
}

export const Wrapper = styled("div")(({ white }: WrapperProps) => ({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: `rgba(${white ? "255, 255, 255, 0.85" : "0, 42, 95, 0.2"})`,
  zIndex: 99999
}))

export const Loader = styledComponent.img`
    animation-iteration-count: infinite;
    height: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
    width: ${(p: { size: LoaderSize }) => getLoaderSize(p.size)}px;
    animation-name: ${spin};
    animation-duration: 1.6s;
    animation-timing-function: ease-in-out;
`
