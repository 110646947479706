import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios"
import paths from "common/config/paths"
import config from "../config"
import * as tokenUtils from "common/utils/token.utils"

const getCommonHeaders = () => {
  const token = localStorage.getItem(config.APP_TOKEN_KEY)
  return {
    Authorization: `Bearer ${token}`,
    "content-type": "application/json"
  }
}

const getRequest = async <T>(
  endpoint: string,
  method: Method,
  settings?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  const url = `${config.API_URL}/${endpoint}`

  try {
    return axios({
      ...settings,
      method,
      url,
      headers: {
        ...getCommonHeaders(),
        ...settings?.headers
      }
    })
  } catch (err) {
    if (err.response && err.response.status === 401) {
      tokenUtils.flush()
      window.location.replace(paths.login)
    }
    return Promise.reject(err)
  }
}

export const get = <T = any>(
  endpoint: string,
  settings?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => getRequest<T>(endpoint, "GET", settings)

export const post = <T = any>(
  endpoint: string,
  settings?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => getRequest<T>(endpoint, "POST", settings)

export const put = <T = any>(
  endpoint: string,
  settings?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => getRequest<T>(endpoint, "PUT", settings)

export const del = <T = any>(
  endpoint: string,
  settings?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => getRequest<T>(endpoint, "DELETE", settings)

export default {
  get,
  post,
  put,
  del
}
