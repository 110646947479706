import { get } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface FetchUsersParams {
  query: {
    search?: string
    take?: number
  }
}

export interface FetchUsersDto {
  data: UserDto[]
}

export interface UserDto {
  id: string
  name: string
  departments: { id: string; name: string }[]
}

export default asyncThunkFactory<UserDto[], FetchUsersParams>(
  NAMESPACE,
  "fetchUsers",
  async (params) => {
    const path = ["users", "basic"].join("/")
    const response = await get<FetchUsersDto>(path, { params: params.query })
    return response.data.data
  }
)
