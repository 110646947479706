import React from "react"

import { StyledToastContainer } from "./ToastContainer.style"

const ToastContainer: React.FC = () => {
  return (
    <StyledToastContainer
      toastClassName={(props) =>
        props?.type === "error"
          ? "Toastify__toast toast-error"
          : "Toastify__toast toast-success"
      }
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default ToastContainer
