import { post } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"
import { DashboardDto } from "../dtos/dashboard.dto"

export interface UpsertDashboardParams {
  body: Partial<DashboardDto>
}

export default asyncThunkFactory<void, UpsertDashboardParams>(
  NAMESPACE,
  "upsertDashboard",
  async (params) => {
    const path = ["dashboard"].join("/")
    const response = await post<void>(path, { data: params.body })
    return response.data
  }
)
