import React from "react"
import { Route } from "common/types/Route"
import paths from "common/config/paths"

export default [
  {
    path: paths.categories.root,
    isPrivate: true,
    component: React.lazy(() => import("./views/CategoryListView"))
  },
  {
    path: paths.categories.create,
    isPrivate: true,
    component: React.lazy(() => import("./views/CreateCategoryFormView"))
  },
  {
    path: paths.categories.edit,
    isPrivate: true,
    component: React.lazy(() => import("./views/EditCategoryFormView"))
  }
] as Route[]
