import { createAsyncThunk } from "@reduxjs/toolkit"
import { notifyError } from "common/utils/toasts.utils"
import { handleUnauthorizedApiResponseError } from "./auth/utils"

/**
 * Base
 */

export interface ApiResourceBase<T> {
  data: T | T[] | null
  isLoading: boolean
  error: any
}

export const handleApiResourceBasePending = <T>(state: ApiResourceBase<T>) => {
  state.error = null
  state.isLoading = true
}

export const handleApiResourceBaseFulfilled = <T>(
  state: ApiResourceBase<T>
) => {
  state.error = null
  state.isLoading = false
}

export const handleApiResourceBaseRejected = <T>(
  state: ApiResourceBase<T>,
  error: any
) => {
  state.error = error
  state.isLoading = false
  handleUnauthorizedApiResponseError(error)
}

/**
 * Single
 */

export interface ApiResource<T> extends ApiResourceBase<T> {
  data: T | null
}

export const defaultApiResource = <T>(): ApiResource<T> => ({
  data: null,
  isLoading: false,
  error: null
})

export const handleApiResourceFulfilled = <T>(
  state: ApiResourceBase<T>,
  data: T
) => {
  state.error = null
  state.isLoading = false
  state.data = data
}

export const handleApiResourceRejected = <T>(
  state: ApiResource<T>,
  error: any
) => {
  handleApiResourceBaseRejected(state, error)
  state.data = null
}

/**
 * List
 */

export interface ListedApiResource<T> extends ApiResourceBase<T> {
  data: T[]
}

export const defaultListedApiResource = <T>(): ListedApiResource<T> => ({
  data: [],
  isLoading: false,
  error: null
})

export const handleListedApiResourceFulfilled = <T>(
  state: ListedApiResource<T>,
  data: T[]
) => {
  state.error = null
  state.isLoading = false
  state.data = data
}

export const handleListedApiResourceRejected = <T>(
  state: ListedApiResource<T>,
  error: any
) => {
  handleApiResourceBaseRejected(state, error)
  state.data = []
}

/**
 * List (paginated)
 */

export interface PaginatedApiResource<T> extends ListedApiResource<T> {
  take: number
  skip: number
  count: number
}

export const defaultPaginatedApiResource = <T>(): PaginatedApiResource<T> => ({
  data: [],
  isLoading: false,
  error: null,
  take: 0,
  skip: 0,
  count: 0
})

/**
 * Factories
 */

export const asyncThunkFactory = <DtoType, ParamsType>(
  namespace: string,
  name: string,
  fn: (params: ParamsType) => Promise<DtoType>
) =>
  createAsyncThunk<DtoType, ParamsType>(
    `${namespace}/${name}`,
    async (params, { rejectWithValue }) => {
      try {
        return await fn(params)
      } catch (err) {
        notifyError((err as any).response.data.message)
        return rejectWithValue(err)
      }
    }
  )
