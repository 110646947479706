import React from "react"
import { Route } from "common/types/Route"
import paths from "common/config/paths"

export default [
  {
    path: paths.schedules.root,
    isPrivate: true,
    component: React.lazy(() => import("./views/SchedulesView"))
  }
] as Route[]
