import { get } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface GetWellnessCalendarsDto {
  data: WellnessCalendarDto[]
}

export interface WellnessCalendarDto {
  id: number
  name: string
  path: string
  hidden: boolean
  createdAt: string
}

export interface FetchWellnessCalendarsParams {}

export default asyncThunkFactory<
  GetWellnessCalendarsDto,
  FetchWellnessCalendarsParams
>(NAMESPACE, "fetchWellnessCalendars", async () => {
  const path = ["uploads", "wellness-calendars"].join("/")
  const response = await get<GetWellnessCalendarsDto>(path)
  return response.data
})
