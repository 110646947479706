import React from "react"
import Spinner from "../Spinner/Spinner"
import { Wrapper } from "./PageLoading.style"

export const PageLoading: React.FC = () => {
  return (
    <Wrapper>
      <Spinner white />
    </Wrapper>
  )
}

export default PageLoading
