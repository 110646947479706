import { createSlice } from "@reduxjs/toolkit"
import {
  ListedApiResource,
  defaultListedApiResource,
  handleListedApiResourceRejected,
  handleListedApiResourceFulfilled,
  handleApiResourceBasePending,
  handleApiResourceBaseFulfilled,
  handleApiResourceBaseRejected
} from "../helpers"
import fetchUserSchedulesAction, {
  UserScheduleDto
} from "./actions/fetch-user-schedules.action"
import createUserScheduleAction from "./actions/create-user-schedule.action"
import deleteUserScheduleAction from "./actions/delete-user-schedule.action"
import updateUserScheduleAction from "./actions/update-user-schedule.action"
import { NAMESPACE } from "./constants"
import { moment } from "common/libs"
import { resetUserSchedulesAction } from "./actions"

interface SchedulesState {
  userSchedules: {
    schedules: ListedApiResource<UserScheduleDto>
    lastSchedulesUpdateTime: string
  }
}

const initialState: SchedulesState = {
  userSchedules: {
    schedules: defaultListedApiResource(),
    lastSchedulesUpdateTime: moment().format()
  }
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchUserSchedulesAction.pending, (state) =>
        handleApiResourceBasePending(state.userSchedules.schedules)
      )
      .addCase(fetchUserSchedulesAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(
          state.userSchedules.schedules,
          action.payload.data
        )
      )
      .addCase(fetchUserSchedulesAction.rejected, (state, action) =>
        handleListedApiResourceRejected(
          state.userSchedules.schedules,
          action.error
        )
      )
      .addCase(createUserScheduleAction.pending, (state) =>
        handleApiResourceBasePending(state.userSchedules.schedules)
      )
      .addCase(createUserScheduleAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.userSchedules.schedules)
        state.userSchedules.lastSchedulesUpdateTime = moment().format()
      })
      .addCase(createUserScheduleAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(
          state.userSchedules.schedules,
          action.error
        )
      )
      .addCase(deleteUserScheduleAction.pending, (state) =>
        handleApiResourceBasePending(state.userSchedules.schedules)
      )
      .addCase(deleteUserScheduleAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.userSchedules.schedules)
        state.userSchedules.lastSchedulesUpdateTime = moment().format()
      })
      .addCase(deleteUserScheduleAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(
          state.userSchedules.schedules,
          action.error
        )
      )
      .addCase(updateUserScheduleAction.pending, (state) =>
        handleApiResourceBasePending(state.userSchedules.schedules)
      )
      .addCase(updateUserScheduleAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.userSchedules.schedules)
        state.userSchedules.lastSchedulesUpdateTime = moment().format()
      })
      .addCase(updateUserScheduleAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(
          state.userSchedules.schedules,
          action.error
        )
      )
      .addCase(resetUserSchedulesAction, (state) => {
        state.userSchedules.schedules = defaultListedApiResource()
      })
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default { reducer: slice.reducer }
