import React from "react"
import Router from "common/components/Router"
import { Provider } from "react-redux"
import store from "common/state/store"
import { StylesProvider } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/core"
import theme from "common/styles/main.theme"

const App: React.FunctionComponent = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
