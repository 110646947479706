import { get } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"
import { DashboardDto } from "../dtos/dashboard.dto"

export interface GetDashboardDto {
  data: DashboardDto
}

export interface FetchDashboardParams {}

export default asyncThunkFactory<GetDashboardDto, FetchDashboardParams>(
  NAMESPACE,
  "fetchDashboard",
  async () => {
    const path = ["dashboard"].join("/")
    const response = await get<GetDashboardDto>(path)
    return response.data
  }
)
