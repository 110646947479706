import config from "common/config"

export const set = (accessToken: string, expiresAt: number) => {
  localStorage.setItem(config.APP_TOKEN_KEY, accessToken)
  localStorage.setItem(config.TOKEN_EXPIRE_AT, expiresAt.toString())
}

export const flush = () => {
  localStorage.removeItem(config.APP_TOKEN_KEY)
  localStorage.removeItem(config.TOKEN_EXPIRE_AT)
  // TODO: Handle refresh token
  // localStorage.removeItem(config.REFRESH_TOKEN_KEY)
}

export const get = () => ({
  accessToken: localStorage.getItem(config.APP_TOKEN_KEY),
  expiresAt: localStorage.getItem(config.TOKEN_EXPIRE_AT)
})

export const hasExpired = () => {
  const { expiresAt } = get()
  if (!expiresAt) return false
  const now = Date.now()
  return Number(expiresAt) < now
}

export const isValid = () => {
  const { accessToken } = get()
  return !hasExpired() && !!accessToken
}
