import { SerializedError } from "common/types"

export const serializeApiError = (err: any): SerializedError => {
  if (err.response?.data) {
    return {
      status: err.response?.status,
      message: err.response?.data?.message
    }
  }

  return {
    status: "Unknown",
    message: "Unknown error"
  }
}
