import { createSlice } from "@reduxjs/toolkit"
import {
  ApiResource,
  defaultApiResource,
  handleApiResourceBaseFulfilled,
  handleApiResourceBasePending,
  handleApiResourceBaseRejected,
  handleApiResourceFulfilled,
  handleApiResourceRejected
} from "../helpers"
import fetchDashboardAction from "./actions/fetch-dashboard.action"
import upsertDashboardAction from "./actions/upsert-dashboard.action"
import { NAMESPACE } from "./constants"
import { DashboardDto } from "./dtos/dashboard.dto"
import { moment } from "common/libs"

interface DashboardState {
  dashboard: {
    data: ApiResource<DashboardDto>
    lastDashboardUpdateTime: string
  }
}

const initialState: DashboardState = {
  dashboard: {
    data: defaultApiResource(),
    lastDashboardUpdateTime: moment().format()
  }
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchDashboardAction.pending, (state) =>
        handleApiResourceBasePending(state.dashboard.data)
      )
      .addCase(fetchDashboardAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.dashboard.data, action.payload.data)
      )
      .addCase(fetchDashboardAction.rejected, (state, action) =>
        handleApiResourceRejected(state.dashboard.data, action.error)
      )
      .addCase(upsertDashboardAction.pending, (state) =>
        handleApiResourceBasePending(state.dashboard.data)
      )
      .addCase(upsertDashboardAction.fulfilled, (state) => {
        handleApiResourceBaseFulfilled(state.dashboard.data)
        state.dashboard.lastDashboardUpdateTime = moment().format()
      })
      .addCase(upsertDashboardAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(state.dashboard.data, action.error)
      )
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default { reducer: slice.reducer }
