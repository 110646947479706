import React from "react"
import { Route } from "common/types/Route"
import paths from "common/config/paths"

export default [
  {
    path: paths.dashboard.root,
    isPrivate: false,
    component: React.lazy(() => import("./views/DashboardView"))
  }
] as Route[]
