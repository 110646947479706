import { createSlice } from "@reduxjs/toolkit"
import {
  ApiResource,
  defaultApiResource,
  defaultPaginatedApiResource,
  handleApiResourceBasePending,
  handleApiResourceBaseRejected,
  handleApiResourceFulfilled,
  handleListedApiResourceFulfilled,
  handleListedApiResourceRejected,
  ListedApiResource
} from "../helpers"
import fetchCurrentUserAction, {
  CurrentUserDto
} from "./actions/fetch-current-user.action"
import fetchUsersAction, { UserDto } from "./actions/fetch-users.action"
import { NAMESPACE } from "./constants"

interface UsersState {
  currentUser: ApiResource<CurrentUserDto>
  users: ListedApiResource<UserDto>
}

const initialState: UsersState = {
  currentUser: defaultApiResource(),
  users: defaultPaginatedApiResource()
}

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchCurrentUserAction.pending, (state) =>
        handleApiResourceBasePending(state.currentUser)
      )
      .addCase(fetchCurrentUserAction.fulfilled, (state, action) =>
        handleApiResourceFulfilled(state.currentUser, action.payload)
      )
      .addCase(fetchCurrentUserAction.rejected, (state, action) =>
        handleApiResourceBaseRejected(state.currentUser, action.error)
      )
      .addCase(fetchUsersAction.pending, (state) =>
        handleApiResourceBasePending(state.users)
      )
      .addCase(fetchUsersAction.fulfilled, (state, action) =>
        handleListedApiResourceFulfilled(state.users, action.payload)
      )
      .addCase(fetchUsersAction.rejected, (state, action) =>
        handleListedApiResourceRejected(state.users, action.error)
      )
})

export const actions = { ...slice.actions, ...slice.caseReducers }

export default { reducer: slice.reducer }
