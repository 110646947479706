import { asyncThunkFactory } from "common/state/helpers"
import { ScheduleEventType } from "common/enums/schedule-event-type.enum"
import { NAMESPACE } from "../constants"
import { get } from "common/api/api"

export interface GetUserSchedulesDto {
  data: UserScheduleDto[]
}

export interface UserScheduleDto {
  id: number
  date: string
  type: ScheduleEventType
}

export interface FetchUserSchedulesParams {
  userId: string
  query: {
    startDate: string
    endDate: string
  }
}

export default asyncThunkFactory<GetUserSchedulesDto, FetchUserSchedulesParams>(
  NAMESPACE,
  "fetchUserShedules",
  async (params) => {
    const path = ["users", params.userId, "schedules"].join("/")
    const response = await get<GetUserSchedulesDto>(path, {
      params: params.query
    })
    return response.data
  }
)
