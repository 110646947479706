import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import paths from "common/config/paths"
import * as tokenUtils from "common/utils/token.utils"

interface ProtectedRouteProps extends RouteProps {
  isPrivate: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isPrivate,
  ...props
}) => {
  const isAuthorized = tokenUtils.isValid()
  if (!isPrivate || isAuthorized) return <Route {...props} />
  return !isPrivate || isAuthorized ? (
    <Route {...props} />
  ) : (
    <Redirect to={paths.login} />
  )
}

export default ProtectedRoute
