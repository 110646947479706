import { post } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface CreateWellnessCalendarDto {
  name: string
  calendar?: File
}

export interface CreateWellnessCalendarParams {
  body: CreateWellnessCalendarDto
}

export default asyncThunkFactory<number, CreateWellnessCalendarParams>(
  NAMESPACE,
  "createWellnessCalendar",
  async (params) => {
    const formData = new FormData()
    formData.append("name", params.body.name)
    formData.append("calendar", params.body.calendar as Blob)

    const path = ["uploads", "wellness-calendars"].join("/")
    const response = await post<number>(path, {
      data: formData,
      headers: { "content-type": "multipart/form-data" }
    })
    return response.data
  }
)
