import { createAsyncThunk } from "@reduxjs/toolkit"
import { del, get, post, put } from "common/api/api"
import endpoints from "common/api/endpoints"
import { OrderingParams, PaginationParams } from "common/api/types"
import {
  FetchCategoriesDto,
  FetchCategoryDto,
  CreateCategoryDto,
  EditCategoryDto
} from "./types"
import { CategoryDto } from "common/dtos/category.dto"
import { handleUnauthorizedApiResponseError } from "../auth/utils"
import { serializeApiError } from "common/utils/errors.utils"
import { SerializedError } from "common/types"

interface FetchCategoriesArgs {
  params?: PaginationParams & OrderingParams
}

export const fetchCategories = createAsyncThunk<
  FetchCategoriesDto,
  FetchCategoriesArgs,
  { rejectValue: SerializedError }
>("categories/fetchCategories", async ({ params }, { rejectWithValue }) => {
  try {
    const response = await get<FetchCategoriesDto>(
      endpoints.categories.many(),
      { params }
    )
    return response.data
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface FetchCategoryArgs {
  categoryId: string
}

export const fetchCategory = createAsyncThunk<
  FetchCategoryDto,
  FetchCategoryArgs,
  { rejectValue: SerializedError }
>("categories/fetchCategory", async ({ categoryId }, { rejectWithValue }) => {
  try {
    const response = await get<FetchCategoryDto>(
      endpoints.categories.one(categoryId)
    )
    return response.data
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface CreateCategoryArgs {
  dto: CreateCategoryDto
}

export const createCategory = createAsyncThunk<
  void,
  CreateCategoryArgs,
  { rejectValue: SerializedError }
>("categories/createCategory", async ({ dto }, { rejectWithValue }) => {
  try {
    await post(endpoints.categories.many(), { data: dto })
  } catch (err) {
    handleUnauthorizedApiResponseError(err)
    const serializedError = serializeApiError(err)
    return rejectWithValue(serializedError)
  }
})

interface EditCategoryArgs {
  categoryId: string
  dto: EditCategoryDto
}

export const editCategory = createAsyncThunk<
  void,
  EditCategoryArgs,
  { rejectValue: SerializedError }
>(
  "categories/editCategory",
  async ({ categoryId, dto }, { rejectWithValue }) => {
    try {
      await put<CategoryDto>(endpoints.categories.one(categoryId), {
        data: dto
      })
    } catch (err) {
      handleUnauthorizedApiResponseError(err)
      const serializedError = serializeApiError(err)
      return rejectWithValue(serializedError)
    }
  }
)

interface DeleteCategoryArgs {
  categoryId: string
}

export const deleteCategory = createAsyncThunk<
  void,
  DeleteCategoryArgs,
  { rejectValue: SerializedError }
>(
  "categories/deleteCategory",
  async ({ categoryId }: DeleteCategoryArgs, { rejectWithValue }) => {
    try {
      await del<CategoryDto>(endpoints.categories.one(categoryId))
    } catch (err) {
      handleUnauthorizedApiResponseError(err)
      const serializedError = serializeApiError(err)
      return rejectWithValue(serializedError)
    }
  }
)
