import * as tokenUtils from "common/utils/token.utils"
import paths from "common/config/paths"

export const logout = () => {
  tokenUtils.flush()
  window.location.replace(paths.login)
}

export const login = (token: string, expiresAt: number) => {
  tokenUtils.set(token, expiresAt)
  window.location.replace(paths.articles.root)
}
