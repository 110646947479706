import React from "react"
import { Route } from "common/types/Route"
import paths from "common/config/paths"

export default [
  {
    path: paths.login,
    isPrivate: false,
    component: React.lazy(() => import("./components/LoginView/LoginView"))
  }
] as Route[]
