import { del } from "common/api/api"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface DeleteUserSchedulesParams {
  scheduleId: number
  userId: string
}

export default asyncThunkFactory<void, DeleteUserSchedulesParams>(
  NAMESPACE,
  "deleteUserSchedule",
  async (params) => {
    const path = ["users", params.userId, "schedules", params.scheduleId].join(
      "/"
    )
    const response = await del<void>(path)
    return response.data
  }
)
