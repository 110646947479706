import { asyncThunkFactory } from "common/state/helpers"
import { ScheduleEventType } from "common/enums/schedule-event-type.enum"
import { NAMESPACE } from "../constants"
import { post } from "common/api/api"

export interface UserScheduleDto {
  date: string
  type: ScheduleEventType
}

export interface CreateUserSchedulesParams {
  body: UserScheduleDto
  userId: string
}

export default asyncThunkFactory<void, CreateUserSchedulesParams>(
  NAMESPACE,
  "createUserSchedule",
  async (params) => {
    const path = ["users", params.userId, "schedules"].join("/")
    const response = await post<void>(path, {
      data: { schedules: [params.body] }
    })
    return response.data
  }
)
