import paths from "common/config/paths"
import { Module } from "common/types/Module"
import { Route as ModuleRoute } from "common/types/Route"
import { modules } from "modules"
import React, { Suspense } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import PageLoading from "./PageLoading/PageLoading"
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute"
import ToastContainer from "./ToastContainer"

// Get all module routes from all modules
const extractRoutesFromModules = (modules: Module[]) =>
  modules.reduce<ModuleRoute[]>((allRoutes, module) => {
    return [...allRoutes, ...module.routes]
  }, [])

// Assign routes from modules to BrowserRouter
const Router: React.FunctionComponent = () => {
  const modulesRoutes = extractRoutesFromModules(modules)

  // TODO: Try to split application routes (after login) and other routes (eg. login)
  // application routes will be rendered inside predefined "View" component
  const routes = modulesRoutes.map((route) => {
    return (
      <ProtectedRoute
        isPrivate={route.isPrivate}
        key={route.path}
        path={route.path}
        render={() => <route.component />}
        exact
      />
    )
  })

  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<PageLoading />}>
        <Switch>
          {routes}
          {/* Redirect to root path when given path is not found */}
          <Route path="*" render={() => <Redirect to={paths.root} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default Router
