import { get } from "common/api/api"
import { UserRole } from "common/enums/user-role.enum"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface CurrentUserDto {
  id: string
  firstName: string
  lastName: string
  email: string
  mobilePhone: string
  businessPhone: string
  imageUrl: string
  jobTitle: string
  role: UserRole
  fullName: string
}

export default asyncThunkFactory<CurrentUserDto, unknown>(
  NAMESPACE,
  "fetchCurrentUser",
  async () => {
    const path = ["users", "me"].join("/")
    const response = await get<CurrentUserDto>(path)
    return response.data
  }
)
