import { createSlice } from "@reduxjs/toolkit"
import { CategoryDto } from "common/dtos/category.dto"
import { notifyError, notifySuccess } from "common/utils/toasts.utils"
import * as thunks from "./thunks"
import { FetchCategoriesDto } from "./types/fetch-categories.dto"

export const SLICE_NAME = "categories"

export interface CategoriesState {
  isFetchLoading: boolean
  isPersistingLoading: boolean
  categories: FetchCategoriesDto | null
  category: CategoryDto | null
}

const initialState: CategoriesState = {
  isFetchLoading: false,
  isPersistingLoading: false,
  categories: null,
  category: null
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(thunks.fetchCategories.pending, (state) => {
        state.isFetchLoading = true
      })
      .addCase(thunks.fetchCategories.rejected, (state, { payload }) => {
        state.isFetchLoading = false
        notifyError("Couldn't get categories!", payload)
      })
      .addCase(thunks.fetchCategories.fulfilled, (state, { payload }) => {
        if (payload) state.categories = payload
        state.isFetchLoading = false
      })
      .addCase(thunks.createCategory.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.createCategory.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't create category!", payload)
      })
      .addCase(thunks.createCategory.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Category created!")
      })
      .addCase(thunks.fetchCategory.pending, (state) => {
        state.isFetchLoading = true
      })
      .addCase(thunks.fetchCategory.rejected, (state, { payload }) => {
        state.isFetchLoading = false
        notifyError("Couldn't get categories!", payload)
      })
      .addCase(thunks.fetchCategory.fulfilled, (state, { payload }) => {
        if (payload) state.category = payload
        state.isFetchLoading = false
      })
      .addCase(thunks.editCategory.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.editCategory.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't update category!", payload)
      })
      .addCase(thunks.editCategory.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Category updated!")
      })
      .addCase(thunks.deleteCategory.pending, (state) => {
        state.isPersistingLoading = true
      })
      .addCase(thunks.deleteCategory.rejected, (state, { payload }) => {
        state.isPersistingLoading = false
        notifyError("Couldn't remove category!", payload)
      })
      .addCase(thunks.deleteCategory.fulfilled, (state) => {
        state.isPersistingLoading = false
        notifySuccess("Category removed!")
      })
})

export default { reducer: slice.reducer }
