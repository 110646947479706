import { put } from "common/api/api"
import { ScheduleEventType } from "common/enums/schedule-event-type.enum"
import { asyncThunkFactory } from "common/state/helpers"
import { NAMESPACE } from "../constants"

export interface CreateScheduleDto {
  date: string
  type: ScheduleEventType
}

export interface UpdateUserScheduleParams {
  body: CreateScheduleDto
  scheduleId: number
  userId: string
}

export default asyncThunkFactory<void, UpdateUserScheduleParams>(
  NAMESPACE,
  "updateUserSchedule",
  async (params) => {
    const path = ["users", params.userId, "schedules", params.scheduleId].join(
      "/"
    )
    const response = await put<void>(path, {
      data: params.body
    })
    return response.data
  }
)
