export const msalConfig = {
  auth: {
    clientId: "76cb02a4-1bef-4c34-bf0e-46f4490e35de",
    authority:
      "https://login.microsoftonline.com/145b82c9-57a6-42c3-82e0-7b44f8a2a1df",
    redirectUri: process.env["REACT_APP_REDIRECT_URL"]
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
}

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    "User.Read",
    "Group.Read.All",
    "GroupMember.Read.All"
  ]
}
